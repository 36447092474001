import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import LoginScreen from './screens/LoginScreen'
import ProductsScreen from './screens/ProductsScreen'
import ProductDetailsScreen from './screens/ProductDetailsScreen'
import MinhaContaScreen from './screens/MinhaContaScreen'
import InformationsScreen from './screens/InformationsScreen'
import InformationProductScreen from './screens/InformationProductScreen'

import './App.css'

const routes = [
  {
    path: '/',
    component: LoginScreen
  },
  {
    path: '/Produtos',
    component: ProductsScreen
  },
  {
    path: '/DetalhesProduto',
    component: ProductDetailsScreen
  },
  {
    path: '/MinhaConta',
    component: MinhaContaScreen
  },
  {
    path: '/Informacoes',
    component: InformationsScreen
  },
  {
    path: '/InformacaoProduto',
    component: InformationProductScreen
  }
];

function App() {
  return (
    <Router>
      <div className='App'>
        <Switch>
          {routes.map((route) => (
            <Route
              exact
              key={route.path}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </div>
    </Router>
  );
}

export default App;
