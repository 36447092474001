import React from 'react'
import styled, { css } from 'styled-components'
import Button from './ButtonLink'
import { ListUl } from '@styled-icons/boxicons-regular/ListUl'
import { Exit } from 'styled-icons/boxicons-regular/Exit';

const Navbar = styled.div`
    height: 100vh;
    background-color: #5D483C;
    flex: 1 1 auto;
    width: 240px;
    display: block;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    padding-left: 40px;
    padding-top: 130px;
    margin: 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    align-items: flex-start;
`;

const navbarIconStyle = css`
    color: #fff;
    height: 24px;
    width: 24px;
`;

const ListUlIcon = styled(ListUl)`
    ${navbarIconStyle}
`;

const ExitIcon = styled(Exit)`
    ${navbarIconStyle}
`;

export default class NavbarLeft extends React.Component {
    render() {
        return (
            <Navbar>
                <Button to='/Produtos' title='Produtos' icon={<ListUlIcon />} />
                <Button to='/' title='Sair' icon={<ExitIcon />} click={() => { localStorage.removeItem('TOKEN'); localStorage.removeItem('USER_ID'); }} />
            </Navbar>
        )
    }
}