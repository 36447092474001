import React from 'react'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import NavbarTop from '../components/NavbarFixedTopInfo'
import noGlutenIcon from '../assets/no_gluten_icon.png'
import noLactoseIcon from '../assets/no_lactose_icon.png'
import noTransgenicIcon from '../assets/no_transgenic_icon.png'
import noConservantsIcon from '../assets/no_conservants_icon.png'
import noSugarIcon from '../assets/no_sugar_icon.png'
import veganIcon from '../assets/vegan_icon.png'

const Container = styled.div`
    flex: 1;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 104px 20px 40px;
`;

const CardTitle = styled.div`
    height: 68px;
    font-size: 20px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 0 0 0 20px;
    background-color: #5D483C;
    border-color: #5D483C;
    border-radius: 4px;
    color: #FFF;
    font-weight: 400;
    display: flex;
    align-items: center;
`;

const CardContainer = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
    padding: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ColumnFlex = styled(Column)`
    flex: 1;
    background-color: #DAEDD5;
    border-radius: 4px;
    padding: 10px;
    margin-right: 10px;
    align-items: flex-start;
`;

const ColumnWrap = styled(Column)`
    width: 240px;
    flex-wrap: wrap;
    flex-direction: row;
`;

const Information = styled.div`
    display: flex;
    flex: 1;
    align-items: flex-start;
`;

const Divider = styled.div`
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #116D3A;
    margin: 10px 0;
`;

const InfoDescription = styled.div`
    text-transform: uppercase;
`;

const InfoTitle = styled.div`
    font-weight: bold;
    font-size: 20px;
    margin-right: 20px; 
`;

const Subtitle = styled(InfoTitle)`
    margin-right: 0;
    margin-bottom: 15px;
    font-size: 16px;
    width: 100%;
    text-align: center;
    font-weight: normal;
`;

const ColorTitle = styled(InfoTitle)`
    color: #116D3A;
    margin-right: 0;
    margin-bottom: 5px;
    width: 100%;
    text-align: center;
`;

const Legenda = styled.div`
    margin: 50px 10px 10px;
`;

const Icon = styled.img`
    height: 100px;
    width: 100px;
    margin: 10px;
`;

const customStyles = {
    rows: {
        style: {
            minHeight: '40px',
            backgroundColor: '#DAEDD5',
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '18px',
            textAlign: 'center',
            backgroundColor: '#DAEDD5',
            color: '#116D3A',
            borderBottom: '1px solid #116D3A',
            borderTop: '1px solid #116D3A',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '16px',
            backgroundColor: '#DAEDD5',
            borderBottom: '1px solid #116D3A'
        },
    },
};

export default class InformationProductScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: localStorage.getItem('PRODUCT_ID'),
            showConfirmationModal: false,
            name: '',
            composition: '',
            benefits: '',
            suggestions: '',
            gluten: null,
            lactose: null,
            transgenic: null,
            conservants: null,
            sugar: null,
            vegan: null,
            portion: '',
            energetic: 0,
            carbohydrates: 0,
            proteins: 0,
            totalfat: 0,
            saturatedfat: 0,
            transfat: 0,
            totalfatvd: 0,
            saturatedfatvd: 0,
            fiber: 0,
            sodium: 0,
            energeticvd: 0,
            carbohydratesvd: 0,
            proteinsvd: 0,
            fibervd: 0,
            sodiumvd: 0,
            productcode: '',
        }
    }

    componentDidMount() {
        window.onpopstate = (e) => {
            e.preventDefault();
            console.log(e)
        }

        this.handleGet();
    }

    handleGet = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/Product/${this.state.id}`;
        console.log(URL)
        axios.get(URL)
            .then(res => {
                console.log(res)
                this.setState({
                    name: res.data.name,
                    composition: res.data.composition,
                    benefits: res.data.benefits,
                    suggestions: res.data.suggestions,
                    gluten: res.data.gluten,
                    lactose: res.data.lactose,
                    transgenic: res.data.transgenic,
                    conservants: res.data.conservants,
                    sugar: res.data.sugar,
                    vegan: res.data.vegan,
                    portion: res.data.portion,
                    energetic: res.data.energetic,
                    carbohydrates: res.data.carbohydrates,
                    proteins: res.data.proteins,
                    totalfat: res.data.totalfat,
                    saturatedfat: res.data.saturatedfat,
                    transfat: res.data.transfat,
                    totalfatvd: res.data.totalfatvd,
                    saturatedfatvd: res.data.saturatedfatvd,
                    fiber: res.data.fiber,
                    sodium: res.data.sodium,
                    energeticvd: res.data.energeticvd,
                    carbohydratesvd: res.data.carbohydratesvd,
                    proteinsvd: res.data.proteinsvd,
                    fibervd: res.data.fibervd,
                    sodiumvd: res.data.sodiumvd,
                    productcode: res.data.productcode,
                });
            }).catch(err => {
                console.log('Erro: ' + err);
            });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleGoBack = () => {
        this.props.history.goBack()
    }

    render() {
        const { name, composition, benefits, suggestions, gluten, lactose, transgenic, conservants, sugar, vegan, portion, energetic, carbohydrates, proteins, totalfat, saturatedfat, transfat, totalfatvd, saturatedfatvd, fiber, sodium, energeticvd, carbohydratesvd, proteinsvd, fibervd, sodiumvd, productcode } = this.state;

        const columns = ([
            {
                selector: 'name',
            },
            {
                name: 'Quant. por porção',
                selector: 'qtd',
                center: true,
                width: '120px'
            },
            {
                name: '% VD (*)',
                selector: 'qtdvd',
                center: true,
                width: '100px'
            }
        ]);

        const data = ([
            {
                name: 'Valor energético*',
                qtd: `${energetic} kcal`,
                qtdvd: `${energeticvd}%`
            },
            {
                name: 'Carboidratos*',
                qtd: `${carbohydrates} g`,
                qtdvd: `${carbohydratesvd}%`
            },
            {
                name: 'Proteínas*',
                qtd: `${proteins} g`,
                qtdvd: `${proteinsvd}%`
            },
            {
                name: 'Gorduras totais*',
                qtd: `${totalfat} g`,
                qtdvd: `${totalfatvd}%`
            },
            {
                name: 'Gorduras saturadas*',
                qtd: `${saturatedfat} g`,
                qtdvd: `${saturatedfatvd}%`
            },
            {
                name: 'Gorduras trans*',
                qtd: `${transfat} g`,
                qtdvd: `**`
            },
            {
                name: 'Fibra alimentar*',
                qtd: `${fiber} g`,
                qtdvd: `${fibervd}%`
            },
            {
                name: 'Sódio*',
                qtd: `${sodium} mg`,
                qtdvd: `${sodiumvd}%`
            }
        ]);

        return (
            <Container>
                <NavbarTop title={'INFORMAÇÕES NUTRICIONAIS'} back={true} click={this.handleGoBack} />
                <Content>
                    <Row>
                        <CardTitle>{name} - {productcode}</CardTitle>
                    </Row>
                    <CardContainer>
                        {composition &&
                            <div>
                                <Information>
                                    <InfoTitle>COMPOSIÇÃO</InfoTitle>
                                    <InfoDescription>{composition}</InfoDescription>
                                </Information>
                                <Divider />
                            </div>
                        }
                        {benefits &&
                            <div>
                                <Information>
                                    <InfoTitle>BENEFÍCIOS</InfoTitle>
                                    <InfoDescription>{benefits}</InfoDescription>
                                </Information>
                                <Divider />
                            </div>
                        }
                        {suggestions &&
                            <div>
                                <Information>
                                    <InfoTitle>SUGESTÕES</InfoTitle>
                                    <InfoDescription>{suggestions}</InfoDescription>
                                </Information>
                                <Divider />
                            </div>
                        }
                    </CardContainer>
                    <CardContainer>
                        <Row>
                            <ColumnFlex>
                                <ColorTitle>Informações Nutricionais</ColorTitle>
                                <Subtitle>{portion}</Subtitle>
                                <DataTable
                                    noHeader={true}
                                    columns={columns}
                                    data={data}
                                    noDataComponent={''}
                                    customStyles={customStyles}
                                    style={{}}
                                />
                            </ColumnFlex>
                            <div>
                                <ColumnWrap>
                                    {gluten == 1 &&
                                        <Icon src={noGlutenIcon} alt='Sem Glúten' ></Icon>
                                    }
                                    {lactose == 1 &&
                                        <Icon src={noLactoseIcon} alt='Sem Lactose' ></Icon>
                                    }
                                    {transgenic == 1 &&
                                        <Icon src={noTransgenicIcon} alt='Sem Transgênico' ></Icon>
                                    }
                                    {conservants == 1 &&
                                        <Icon src={noConservantsIcon} alt='Sem Conservantes' ></Icon>
                                    }
                                    {sugar == 1 &&
                                        <Icon src={noSugarIcon} alt='Sem Açúcar' ></Icon>
                                    }
                                    {vegan == 1 &&
                                        <Icon src={veganIcon} alt='Vegano' ></Icon>
                                    }
                                </ColumnWrap>
                            </div>
                        </Row>
                        <Legenda>* Valores diários de referência com base em uma dieta de 2000kcal ou 8400kj. Seus valores diários podem ser maiores ou menores dependendo de suas necessidades energéticas. ** Valor diário não estabelecido. *** Não determinado. **** Alérgicos: pode conter Trigo, Centeio, Cevada, Aveia, Amendoim, Soja, Amêndoa, Avelã, Nozes, Castanha de caju, Castanha do Pará, Macadâmia, Pistache, Noz pecan e Leite.</Legenda>
                    </CardContainer>
                </Content>
            </Container>
        )
    }
}
