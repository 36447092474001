import React from 'react'
import styled from 'styled-components'
import { Redirect, withRouter } from 'react-router-dom'
import axios from 'axios'
import NavbarTop from '../components/NavbarFixedTop'
import NavbarLeft from '../components/NavbarLeft'
import Button from '../components/ButtonRounded'
import Input from '../components/InputText'

const Container = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

const ContainerFlexNone = styled(Container)`
    flex: none;
    margin-right: 20px;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 280px;
    padding: 124px 50px;
`;

const CardTitle = styled.div`
    height: 68px;
    font-size: 24px;
    text-align: center;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 0 0 0 20px;
    background-color: #fff;
    border-color: #5D483C;
    border-radius: 4px;
    color: #5D483C;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CardContainer = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 40px;
    padding: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const RowCenter = styled(Row)`
    justify-content: center;
`;

const Title = styled.div`
    color: #116D3A;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
`;

const Subtitle = styled.div`
    color: #116D3A;
    font-size: 20px;
    font-weight: 500;
    margin: 10px 0;
`;

const Label = styled.div`
    height: 68px;
    font-size: 18px;
    display: flex;
    align-items: center;
    flex: none;
`;

const CheckboxLabel = styled.div`
    color: #116D3A;
    margin: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;
    font-weight: 500;
`;

const Error = styled.div`
    color: #FF0000;
    margin-left: 10px;
`;

class ProductDetailsScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: localStorage.getItem('TOKEN') == null,
            create: localStorage.getItem('CREATE'),
            id: localStorage.getItem('PRODUCT_ID') || '',
            duplicate: localStorage.getItem('DUPLICATE'),
            name: '',
            composition: '',
            benefits: '',
            suggestions: '',
            gluten: 0,
            lactose: 0,
            transgenic: 0,
            conservants: 0,
            sugar: 0,
            vegan: 0,
            portion: '',
            energetic: 0,
            carbohydrates: 0,
            proteins: 0,
            totalfat: 0,
            saturatedfat: 0,
            transfat: 0,
            totalfatvd: 0,
            saturatedfatvd: 0,
            fiber: 0,
            sodium: 0,
            energeticvd: 0,
            carbohydratesvd: 0,
            proteinsvd: 0,
            fibervd: 0,
            sodiumvd: 0,
            productcode: '',
            error: null,
        }
    }

    componentDidMount() {
        this.handleGet();
    }

    handleRequest = () => {
        if (this.state.create == 'true' || this.state.duplicate == 'true') {
            this.handleCreate();
        } else {
            this.handleUpdate();
        }
    }

    handleGet = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/Product/${this.state.id}`;

        axios.get(URL)
            .then(res => {
                this.setState({
                    name: res.data.name || '',
                    composition: res.data.composition || '',
                    benefits: res.data.benefits || '',
                    suggestions: res.data.suggestions || '',
                    gluten: res.data.gluten,
                    lactose: res.data.lactose,
                    transgenic: res.data.transgenic,
                    conservants: res.data.conservants,
                    sugar: res.data.sugar,
                    vegan: res.data.vegan,
                    portion: res.data.portion || '',
                    energetic: res.data.energetic || 0,
                    carbohydrates: res.data.carbohydrates || 0,
                    proteins: res.data.proteins || 0,
                    totalfat: res.data.totalfat || 0,
                    saturatedfat: res.data.saturatedfat || 0,
                    transfat: res.data.transfat || 0,
                    totalfatvd: res.data.totalfatvd || 0,
                    saturatedfatvd: res.data.saturatedfatvd || 0,
                    fiber: res.data.fiber || 0,
                    sodium: res.data.sodium || 0,
                    energeticvd: res.data.energeticvd || 0,
                    carbohydratesvd: res.data.carbohydratesvd || 0,
                    proteinsvd: res.data.proteinsvd || 0,
                    fibervd: res.data.fibervd || 0,
                    sodiumvd: res.data.sodiumvd || 0,
                    productcode: res.data.productcode,
                });
            }).catch(err => {
                console.log('Erro: ' + err);
            });
    }

    handleCreate = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/Product`;
        console.log(this.state)
        axios.post(URL,
            {
                name: this.state.name,
                composition: this.state.composition,
                benefits: this.state.benefits,
                suggestions: this.state.suggestions,
                gluten: this.state.gluten || 0,
                lactose: this.state.lactose || 0,
                transgenic: this.state.transgenic || 0,
                conservants: this.state.conservants || 0,
                sugar: this.state.sugar || 0,
                vegan: this.state.vegan || 0,
                portion: this.state.portion,
                energetic: parseFloat(this.state.energetic),
                carbohydrates: parseFloat(this.state.carbohydrates),
                proteins: parseFloat(this.state.proteins),
                totalfat: parseFloat(this.state.totalfat),
                saturatedfat: parseFloat(this.state.saturatedfat),
                transfat: parseFloat(this.state.transfat),
                totalfatvd: parseFloat(this.state.totalfatvd),
                saturatedfatvd: parseFloat(this.state.saturatedfatvd),
                fiber: parseFloat(this.state.fiber),
                sodium: parseFloat(this.state.sodium),
                energeticvd: parseFloat(this.state.energeticvd),
                carbohydratesvd: parseFloat(this.state.carbohydratesvd),
                proteinsvd: parseFloat(this.state.proteinsvd),
                fibervd: parseFloat(this.state.fibervd),
                sodiumvd: parseFloat(this.state.sodiumvd),
                productcode: this.state.productcode,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
                }
            })
            .then(res => {
                console.log(res)
                this.props.history.push('/Produtos');
            }).catch(err => {
                console.log('Erro: ' + err);
                if (err.response.status === 409) {
                    this.setState({ error: err.response });
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                }
            });
    }

    handleUpdate = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/Product`;
        console.log(this.state)

        axios.put(URL,
            {
                id: parseInt(this.state.id),
                name: this.state.name,
                composition: this.state.composition,
                benefits: this.state.benefits,
                suggestions: this.state.suggestions,
                gluten: this.state.gluten || 0,
                lactose: this.state.lactose || 0,
                transgenic: this.state.transgenic || 0,
                conservants: this.state.conservants || 0,
                sugar: this.state.sugar || 0,
                vegan: this.state.vegan || 0,
                portion: this.state.portion,
                energetic: parseFloat(this.state.energetic),
                carbohydrates: parseFloat(this.state.carbohydrates),
                proteins: parseFloat(this.state.proteins),
                totalfat: parseFloat(this.state.totalfat),
                saturatedfat: parseFloat(this.state.saturatedfat),
                transfat: parseFloat(this.state.transfat),
                totalfatvd: parseFloat(this.state.totalfatvd),
                saturatedfatvd: parseFloat(this.state.saturatedfatvd),
                fiber: parseFloat(this.state.fiber),
                sodium: parseFloat(this.state.sodium),
                energeticvd: parseFloat(this.state.energeticvd),
                carbohydratesvd: parseFloat(this.state.carbohydratesvd),
                proteinsvd: parseFloat(this.state.proteinsvd),
                fibervd: parseFloat(this.state.fibervd),
                sodiumvd: parseFloat(this.state.sodiumvd),
                productcode: this.state.productcode,
            },
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
                }
            })
            .then(res => {
                console.log(res)
                this.props.history.push('/Produtos');
            }).catch(err => {
                console.log('Erro: ' + err);
            });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { id, name, composition, benefits, suggestions, gluten, lactose, transgenic, conservants, sugar, vegan, portion, energetic, carbohydrates, proteins, totalfat, saturatedfat, transfat, fiber, sodium, energeticvd, carbohydratesvd, proteinsvd, totalfatvd, saturatedfatvd, fibervd, sodiumvd, productcode, redirect, error } = this.state;
        if (redirect) {
            return <Redirect to='/' />
        } else {
            return (
                <Container>
                    <NavbarTop />
                    <NavbarLeft />
                    <Content>
                        <Row>
                            <CardTitle>PRODUTO</CardTitle>
                        </Row>
                        <CardContainer>
                            <Title>INFORMAÇÕES BÁSICAS</Title>
                            <Row>
                                <ContainerFlexNone>
                                    <Label>CÓDIGO</Label>
                                    <Label>NOME</Label>
                                    <Label>COMPOSIÇÃO</Label>
                                    <Label>BENEFÍCIOS</Label>
                                    <Label>SUGESTÃO DE CONSUMO</Label>
                                </ContainerFlexNone>
                                <Container>
                                    <Input type='number' name='productcode' value={productcode} width={'auto'} change={this.handleChange} required='required' />
                                    {error &&
                                        <Error>{error.data}</Error>
                                    }
                                    <Input type='text' name='name' value={name} width={'auto'} change={this.handleChange} required='required' />
                                    <Input type='text' name='composition' value={composition} width={'auto'} change={this.handleChange} required='required' />
                                    <Input type='text' name='benefits' value={benefits} width={'auto'} change={this.handleChange} required='required' />
                                    <Input type='text' name='suggestions' value={suggestions} width={'auto'} change={this.handleChange} required='required' />
                                </Container>
                            </Row>
                            <RowCenter>
                                <CheckboxLabel>
                                    <input type='checkbox' name='gluten' checked={gluten} onChange={() => this.setState({ gluten: Number(!gluten) })} />
                                    Sem Glúten
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input type='checkbox' name='lactose' checked={lactose} onChange={() => this.setState({ lactose: Number(!lactose) })} />
                                    Sem Lactose
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input type='checkbox' name='transgenic' checked={transgenic} onChange={() => this.setState({ transgenic: Number(!transgenic) })} />
                                    Não Transgênico
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input type='checkbox' name='conservants' checked={conservants} onChange={() => this.setState({ conservants: Number(!conservants) })} />
                                    Sem Conservantes
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input type='checkbox' name='sugar' checked={sugar} onChange={() => this.setState({ sugar: Number(!sugar) })} />
                                    Zero Açúcar
                                </CheckboxLabel>
                                <CheckboxLabel>
                                    <input type='checkbox' name='vegan' checked={vegan} onChange={() => this.setState({ vegan: Number(!vegan) })} />
                                    Vegano
                                </CheckboxLabel>
                            </RowCenter>
                        </CardContainer>
                        <CardContainer>
                            <Title>INFORMAÇÕES NUTRICIONAIS</Title>
                            <Row>
                                <Label>Porção*</Label>
                                <Container>
                                    <Input type='text' name='portion' value={portion} width={'auto'} change={this.handleChange} required='required' />
                                </Container>
                            </Row>
                            <Row>
                                <ContainerFlexNone>
                                    <Subtitle>Tabela</Subtitle>
                                    <Label>Valor Energético*</Label>
                                    <Label>Carboidratos*</Label>
                                    <Label>Proteínas*</Label>
                                    <Label>Gorduras totais*</Label>
                                    <Label>Gorduras saturadas*</Label>
                                    <Label>Gorduras trans*</Label>
                                    <Label>Fibra alimentar*</Label>
                                    <Label>Sódio*</Label>
                                </ContainerFlexNone>
                                <ContainerFlexNone>
                                    <Subtitle>Qtd. por porção</Subtitle>
                                    <Container>
                                        <Row>
                                            <Input type='number' name='energetic' value={energetic} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>kcal</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='carbohydrates' value={carbohydrates} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>g</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='proteins' value={proteins} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>g</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='totalfat' value={totalfat} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>g</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='saturatedfat' value={saturatedfat} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>g</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='transfat' value={transfat} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>g</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='fiber' value={fiber} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>g</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='sodium' value={sodium} width={'60px'} change={this.handleChange} required='required' />
                                            <Label>mg</Label>
                                        </Row>
                                    </Container>
                                </ContainerFlexNone>
                                <Container>
                                    <Subtitle>% VD (*)</Subtitle>
                                    <Container>
                                        <Row>
                                            <Input type='number' name='energeticvd' value={energeticvd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='carbohydratesvd' value={carbohydratesvd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='proteinsvd' value={proteinsvd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='totalfatvd' value={totalfatvd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='saturatedfatvd' value={saturatedfatvd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                        <Row>
                                            <Label>**</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='fibervd' value={fibervd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                        <Row>
                                            <Input type='number' name='sodiumvd' value={sodiumvd} width={'30px'} change={this.handleChange} required='required' />
                                            <Label>%</Label>
                                        </Row>
                                    </Container>
                                </Container>
                            </Row>
                            <RowCenter>
                                <Button type='button' title={'Salvar'} click={() => this.handleRequest()} />
                            </RowCenter>
                        </CardContainer>
                    </Content>
                </Container>
            )
        }
    }
}

export default withRouter(ProductDetailsScreen);