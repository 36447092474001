import React from 'react'
import styled from 'styled-components'
import { Link, Redirect, withRouter } from 'react-router-dom'
import { Roller } from 'react-awesome-spinners'
import DataTable from 'react-data-table-component'
import ReactModal from 'react-modal'
import axios from 'axios'
import NavbarTop from '../components/NavbarFixedTop'
import NavbarLeft from '../components/NavbarLeft'
import Button from '../components/ButtonRounded'
import Input from '../components/InputText'
import { Plus } from 'styled-icons/boxicons-regular/Plus'
import { Close } from 'styled-icons/remix-fill/Close'
import { Pencil } from 'styled-icons/boxicons-regular/Pencil'
import { Trash } from 'styled-icons/boxicons-regular/Trash'
import { Duplicate } from 'styled-icons/boxicons-regular/Duplicate'

const Container = styled.div`
    flex: 1;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 280px;
    padding: 0 50px;
    padding-top: 124px;
`;

const CardTitle = styled.div`
    height: 68px;
    font-size: 24px;
    text-align: center;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 0 0 0 20px;
    background-color: #fff;
    border-color: #5D483C;
    border-radius: 4px;
    color: #5D483C;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CardContainer = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 40px;
    padding: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const PencilIcon = styled(Pencil)`
    color: #116D3A;
    height: 20px;
    width: 20px;
`;

const TrashIcon = styled(Trash)`
    color: #C1272D;
    height: 20px;
    width: 20px;
`;

const PlusIcon = styled(Plus)`
    color: #fff;
    height: 24px;
    width: 24px;
`;

const CloseIcon = styled(Close)`
    color: #116D3A;
    height: 20px;
    width: 20px;
`;

const DuplicateIcon = styled(Duplicate)`
    color: #006DB7;
    height: 20px;
    width: 20px;
`;

const Spinner = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const TextColored = styled.h3`
    color: #116D3A;
`;

const customStyles = {
    rows: {
        style: {
            minHeight: '60px',
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '18px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '16px',
        },
    },
};

const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        flexDirection: 'column',
        padding: 0
    }
};

const ModalHeader = styled.div`
    flex: 1;
    display: flex;
    background-color: #116D3A;
    justify-content: space-between;
    align-items: center;
`;

const ModalTitle = styled.span`
    color: #fff;
    font-size: 16px;
    margin-left: 20px;
    text-transform: uppercase;
`;

const ModalBody = styled.form`
    padding: 40px 80px;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ModalFooter = styled.div`
    display: flex;
    justify-content: center;
    padding-bottom: 20px;
`;

ReactModal.setAppElement('#root');
class ProductsScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: localStorage.getItem('TOKEN') == null,
            showConfirmationModal: false,
            loading: false,
            id: '',
            name: '',
            products: [],
            auxProducts: [],
            search: '',
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.handleGet();
    }

    handleRequest = (event) => {
        event.preventDefault();
        this.handleCreate();
    }

    handleGet = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/Product`;

        axios.get(URL)
            .then(res => {
                console.log(res)
                this.setState({
                    products: res.data,
                    auxProducts: res.data,
                    loading: false
                });
            }).catch(err => {
                console.log('Erro: ' + err);
                this.setState({ loading: false });
            });
    }

    handleDelete = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/Product?id=${this.state.id}`;

        axios.delete(URL,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
                }
            })
            .then(res => {
                console.log(res)
                this.handleGet();
                this.setState({ loading: false });
                this.handleCloseConfirmationModal();
            }).catch(err => {
                console.log('Erro: ' + err);
                this.setState({ loading: false });
                this.handleCloseConfirmationModal();
            });
    }

    handleOpenConfirmationModal = (row) => {
        this.setState({
            showConfirmationModal: true,
            id: row.id,
            name: row.name,
        })
    }

    handleCloseConfirmationModal = () => {
        this.setState({ showConfirmationModal: false });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleDuplicateProduct = (row) => {
        localStorage.setItem('PRODUCT_ID', row.id);
        localStorage.setItem('DUPLICATE', true);
        localStorage.setItem('CREATE', false);
        this.props.history.push('/DetalhesProduto');
    }

    handleUpdateProduct = (row) => {
        localStorage.setItem('PRODUCT_ID', row.id);
        localStorage.setItem('DUPLICATE', false);
        localStorage.setItem('CREATE', false);
        this.props.history.push('/DetalhesProduto');
    }

    handleAddProduct = () => {
        localStorage.removeItem('PRODUCT_ID');
        localStorage.setItem('DUPLICATE', false);
        localStorage.setItem('CREATE', true);
    }

    handleSearch = (event) => {
        this.handleChange(event);
        this.setState({ auxProducts: event.target.value.length > 0 ? this.state.products.filter(x => x.name.toUpperCase().includes(event.target.value.toUpperCase())) : this.state.products })
    }

    render() {
        const { auxProducts, loading, redirect, search } = this.state;

        const columns = ([
            {
                name: 'CÓDIGO',
                selector: 'productcode',
                width: '200px'
            },
            {
                name: 'NOME',
                selector: 'name',
            },
            {
                selector: 'duplicate',
                cell: row => <Button outlined='#006DB7' title={<DuplicateIcon />} click={() => this.handleDuplicateProduct(row)} />,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                selector: 'edit',
                cell: row => <Button outlined='#116D3A' title={<PencilIcon />} click={() => this.handleUpdateProduct(row)} />,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            },
            {
                selector: 'remove',
                cell: row => <Button outlined='#C1272D' title={<TrashIcon />} click={() => this.handleOpenConfirmationModal(row)} />,
                ignoreRowClick: true,
                allowOverflow: true,
                button: true,
            }
        ]);

        if (redirect) {
            return <Redirect to='/' />
        } else {
            return (
                <Container>
                    <NavbarTop />
                    <NavbarLeft />
                    <Content>
                        <Row>
                            <CardTitle>
                                TODOS OS PRODUTOS
                                <Input type='text' placeholder='Pesquisar produto' name='search' value={search} change={this.handleSearch} />
                                <Link to={'/DetalhesProduto'}><Button type='button' title={<PlusIcon />} click={() => this.handleAddProduct()} /></Link>
                            </CardTitle>
                        </Row>
                        <CardContainer>
                            {
                                loading && <Spinner><Roller /></Spinner>
                            }
                            {
                                <DataTable
                                    noHeader={true}
                                    columns={columns}
                                    data={auxProducts}
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    paginationPerPage={10}
                                    pagination={true}
                                    noDataComponent={''}
                                    customStyles={customStyles}
                                    style={{}}
                                    onRowClicked={(row) => this.handleUpdateProduct(row)}
                                    pointerOnHover={true}
                                />
                            }
                        </CardContainer>
                    </Content>
                    <ReactModal
                        isOpen={this.state.showConfirmationModal}
                        style={modalStyles}
                        contentLabel='Excluir Produto'
                        onRequestClose={this.handleCloseConfirmationModal}
                    >
                        <ModalHeader>
                            <ModalTitle><h3>Excluir Produto</h3></ModalTitle>
                            <Button outlined='#116D3A' title={<CloseIcon />} click={this.handleCloseConfirmationModal} />
                        </ModalHeader>
                        <ModalBody>
                            <TextColored>TEM CERTEZA QUE DESEJA EXCLUIR ESTE PRODUTO?</TextColored>
                        </ModalBody>
                        <ModalFooter>
                            <Button type='button' title='cancelar' click={this.handleCloseConfirmationModal} />
                            <Button type='submit' title='excluir' color='#C1272D' click={this.handleDelete} />
                        </ModalFooter>
                    </ReactModal>
                </Container>
            )
        }
    }
}

export default withRouter(ProductsScreen);