import React from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import { Roller } from 'react-awesome-spinners'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import NavbarTop from '../components/NavbarFixedTopInfo'
import Input from '../components/InputText'

const Container = styled.div`
    flex: 1;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    padding-top: 104px;
`;

const CardTitle = styled.div`
    height: 68px;
    font-size: 20px;
    text-align: center;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 0 0 0 20px;
    background-color: #fff;
    border-color: #5D483C;
    border-radius: 4px;
    color: #5D483C;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const CardContainer = styled.div`
    background-color: #fff;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const Spinner = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

const customStyles = {
    rows: {
        style: {
            minHeight: '60px',
        }
    },
    headCells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '18px',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '16px',
        },
    },
};

class InformationsScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmationModal: false,
            loading: false,
            id: '',
            name: '',
            products: [],
            auxProducts: [],
            search: '',
            rowsPerPage: 10,
            totalRows: 0,
        }
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.handleGetTotalRows();
        this.handleGetByPage(1);
    }

    handleGetByPage = (page) => {
        this.setState({ loading: true });
        var URL = `${process.env.REACT_APP_API_URL}/api/Product/${page}/${this.state.rowsPerPage}`;
        console.log(page)

        axios.get(URL)
            .then(res => {
                this.setState({
                    products: res.data,
                    auxProducts: res.data,
                    loading: false
                });
            }).catch(err => {
                this.setState({ loading: false });
            });
    }

    handleGetTotalRows = () => {
        this.setState({ loading: true });
        var URL = `${process.env.REACT_APP_API_URL}/api/Product/Total`;

        axios.get(URL)
            .then(res => {
                this.setState({
                    totalRows: res.data,
                });
            }).catch(err => {
            });
    }    

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    handleSearch = (event) => {
        this.setState({ loading: true });
        this.handleChange(event);

        if (event.target.value) {
            var URL = `${process.env.REACT_APP_API_URL}/api/Product/Search/${event.target.value}`;

            axios.get(URL)
                .then(res => {
                    this.setState({
                        auxProducts: res.data,
                        rowsPerPage: res.data.length,
                        loading: false
                    });
                }).catch(err => {
                    this.setState({ loading: false });
                });
        } else {
            this.handleGetByPage(1);
        }
    }

    handleProduct = (row) => {
        localStorage.setItem('PRODUCT_ID', row.id);
        this.props.history.push('/InformacaoProduto')
    }

    handleChangeRowsPerPage = (rows) => {
        this.setState({ rowsPerPage: rows });
    }

    render() {
        const { auxProducts, loading, search, rowsPerPage, totalRows } = this.state;

        const columns = ([
            {
                name: 'CÓDIGO',
                selector: 'productcode',
                width: '160px'
            },
            {
                name: 'NOME',
                selector: 'name',
            }
        ]);

        return (
            <Container>
                <NavbarTop title={'INFORMAÇÕES NUTRICIONAIS'} />
                <Content>
                    <Row>
                        <CardTitle>
                            PRODUTOS
                                <Input type='text' placeholder='Pesquisar produto' name='search' value={search} change={this.handleSearch} />
                        </CardTitle>
                    </Row>
                    <CardContainer>
                        <DataTable
                            noHeader={true}
                            columns={columns}
                            data={auxProducts}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            paginationPerPage={rowsPerPage}
                            paginationServer={true}
                            pagination={true}
                            paginationTotalRows={totalRows}
                            progressPending={loading}
                            progressComponent={<Spinner><Roller /></Spinner>}
                            onChangePage={(page) => this.handleGetByPage(page)}
                            onChangeRowsPerPage={(rows) => this.handleChangeRowsPerPage(rows)}
                            noDataComponent={''}
                            customStyles={customStyles}
                            style={{}}
                            onRowClicked={(row) => this.handleProduct(row)}
                            pointerOnHover={true}
                        />
                    </CardContainer>
                </Content>
            </Container>
        )
    }
}

export default withRouter(InformationsScreen);