import React from 'react'
import styled from 'styled-components'
import { Link, withRouter } from 'react-router-dom'
import axios from 'axios'
import Input from '../components/InputText'
import Button from '../components/ButtonRounded'
import background from '../assets/login_background.jpg'
import logo from '../assets/logo.png'

const Container = styled.div`
    display: flex;
    flex: 1;
    justify-align: center;
    align-items: center;
    align-self: center;
`;

const ContainerForm = styled.form`
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-align: center;
    align-items: center;
    align-self: center;
    width: 400px;
    z-index: 1;
`;

const Filter = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(218, 237, 213, 0.83);   
`;

const Background = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
`;

const Logo = styled.img`
    height: 240px;
    width: auto;
    margin-bottom: 20px;
`;

const Title = styled.h2`
    color: #5D483C;
`;

const Anchor = styled(Link)`
    color: #5D483C;
    text-decoration: none;
    margin: 10px 0;
`;

const Error = styled.div`
    color: #FF0000;
`;

class LoginScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            error: null,
        }
    }

    componentDidMount() {
        if (localStorage.getItem('USER_ID')) {
            this.props.history.push('/Produtos');
        }
    }

    handleLogin = (event) => {
        event.preventDefault();
        var URL = `${process.env.REACT_APP_API_URL}/api/User/Login`;

        axios.post(URL,
            {
                email: this.state.email,
                password: this.state.password
            },
            {
                headers:
                {
                    Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
                }
            })
            .then(res => {
                console.log(res)
                localStorage.setItem('TOKEN', res.data.token);
                localStorage.setItem('USER_ID', res.data.user.id);
                this.props.history.push('/Produtos');
            }).catch(err => {
                if (err.response.status === 403) {
                    this.setState({ error: err.response });
                }
                console.log(err.response);
            });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { email, password, error } = this.state;

        return (
            <Container>
                <Background src={background} />
                <Filter />
                <ContainerForm onSubmit={this.handleLogin}>
                    <Logo src={logo} alt='Sr A Granel' />
                    <Title>LOGIN</Title>
                    <Input type='email' placeholder='E-mail' name='email' value={email} change={this.handleChange} required='required' />
                    <Input type='password' placeholder='Senha' name='password' value={password} change={this.handleChange} required='required' />
                    {error &&
                        <Error>{error.data}</Error>
                    }
                    <Button type='submit' title='entrar' />
                    <Anchor to='/Informacoes'>Informações</Anchor>
                </ContainerForm>
            </Container>
        )
    }
}

export default withRouter(LoginScreen);