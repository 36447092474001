import React from 'react'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'
import axios from 'axios'
import NavbarTop from '../components/NavbarFixedTop'
import NavbarLeft from '../components/NavbarLeft'
import Input from '../components/InputText'
import Button from '../components/ButtonRounded'

const Container = styled.div`
    flex: 1;
`;

const Content = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 280px;
    padding: 0 50px;
    padding-top: 124px;
`;

const CardTitle = styled.div`
    font-size: 24px;
    text-align: center;
    line-height: 2rem;
    font-family: 'Roboto', sans-serif;
    padding: 16px;
    background-color: #5D483C;
    border-color: #5D483C;
    border-radius: 4px;
    color: #fff;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px;
    margin-top: 40px;
    padding: 20px;
`;

const Error = styled.div`
    color: #FF0000;
    margin-left: 10px;
`;

const Success = styled.div`
    color: #4CAF50;
    margin-left: 10px;
`;

export default class AlunosScreen extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: localStorage.getItem('TOKEN') == null,
            email: '',
            oldpassword: '',
            newpassword: '',
            newpassword_confirmation: '',
            error: null,
            password_error: false,
            password_success: false,
        }
    }

    componentDidMount() {
        this.handleGet();
    }

    handleGet = () => {
        var URL = `${process.env.REACT_APP_API_URL}/api/User/${localStorage.getItem('USER_ID')}`;

        axios.get(URL,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
                }
            })
            .then(res => {
                console.log(res)
                this.setState({
                    email: res.data.email,
                });
            }).catch(err => {
                console.log('Erro: ' + err);
            });
    }

    handleUpdate = () => {
        if (this.state.newpassword == this.state.newpassword_confirmation) {
            this.setState({
                error: null,
                password_error: false,
                password_success: false,
            });

            var URL = `${process.env.REACT_APP_API_URL}/api/User/Password`;

            axios.put(URL,
                {
                    id: parseInt(localStorage.getItem('USER_ID')),
                    oldpassword: this.state.oldpassword,
                    newpassword: this.state.newpassword,
                },
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('TOKEN')
                    }
                })
                .then(res => {
                    console.log(res)
                    this.setState({
                        oldpassword: '',
                        newpassword: '',
                        newpassword_confirmation: '',
                        password_success: true,
                    })
                }).catch(err => {
                    if (err.response.status === 403) {
                        this.setState({ error: err.response });
                    }
                    console.log('Erro: ' + err);
                });
        } else {
            this.setState({ password_error: true });
        }
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {
        const { email, oldpassword, newpassword, newpassword_confirmation, redirect, password_error, password_success, error } = this.state;

        if (redirect) {
            return <Redirect to='/' />
        } else {
            return (
                <Container>
                    <NavbarTop />
                    <NavbarLeft />
                    <Content>
                        <CardTitle>MINHA CONTA</CardTitle>
                        <CardContainer>
                            <Input type='email' placeholder='E-mail' name='email' value={email} readOnly />
                        </CardContainer>
                        <CardContainer>
                            <Input type='password' placeholder='Senha atual' name='oldpassword' value={oldpassword} change={this.handleChange} />
                            <Input type='password' placeholder='Nova senha' name='newpassword' value={newpassword} change={this.handleChange} />
                            <Input type='password' placeholder='Repetir nova senha' name='newpassword_confirmation' value={newpassword_confirmation} change={this.handleChange} />
                            {error &&
                                <Error>{error.data}</Error>
                            }
                            {password_error &&
                                <Error>Senhas não correspondem.</Error>
                            }
                            {password_success &&
                                <Success>Senha alterada com sucesso!</Success>
                            }
                            <div>
                                <Button type='button' title='salvar' click={this.handleUpdate} />
                            </div>
                        </CardContainer>
                    </Content>
                </Container>
            )
        }
    }
}