import React from 'react'
import styled from 'styled-components'
import logo from '../assets/logo.png'
import backIcon from '../assets/back_icon.png'

const Navbar = styled.div`
    height: 84px;
    background-color: #DAEDD5;
    flex: 1 1 auto;
    width: 100%;
    display: block;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    margin: 0;
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    align-items: center;
    justify-content: center;
`;

const NavbarContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -100px;
`;

const Back = styled.img`
    height: 50px;
    width: 50px;
    margin-left: 50px;
    cursor: pointer;
    z-index: 1;
`;

const Logo = styled.img`
    height: 60px;
    width: 120px;
`;

const Title = styled.h3`
    color: #5D483C;
    text-weight: bold;
    margin-left: 50px;
`;

export default class NavbarFixedTopInfo extends React.Component {
    render() {
        return (
            <Navbar>
                {this.props.back && <Back src={backIcon} alt='Sem Lactose' onClick={this.props.click}></Back>}
                <NavbarContainer>
                    <Logo src={logo} alt='Sr A Granel' />
                    <Title>{this.props.title}</Title>
                </NavbarContainer>
            </Navbar>
        )
    }
}